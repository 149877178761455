import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import * as classes from "../../styles/auditdemo/audit-demo.module.scss";
import forbesNews from "../../assets/images/auditDemo/forbes.png";
import techcrunchNews from "../../assets/images/auditDemo/techcrunch.png";
import techcrunsh from "../../assets/images/homepage/techcrunsh.png";
import cnbc from "../../assets/images/homepage/cnbc.png";
import forbes from "../../assets/images/homepage/forbes.png";
import cnbcNews from "../../assets/images/auditDemo/cnbc.png";
import BlogCard from "../HomePage/BlogCard";
import Carousel from '../Common/Carousels'
import * as styles from "../../styles/Homepage/Home.module.scss"

const Featured = () => {
  return (
    <>
    <section className={`${styles['blog']}`}>
      <Container className={classes.audit_demo__featured}>
        <Row className="justify-content-center">
          <Col lg={8} xs={12} className="text-center p-0">
            <div className={classes.heading}>
              <h2>
                As <span>featured</span> on
              </h2>
            </div>
          </Col>
        </Row>
        <Row className={classes.feature_cards}>
          <Col lg={4} md={6} xs={12} className="p-0 mb-2">
            <BlogCard
              heading={`Realizing The Potential Of Cloud Computing With Lucidity`}
              subheading="Forbes talks on how businesses today are missing out on many of the supposed benefits of cloud shift, and how Lucidity can help."
              imgSrc={forbesNews}
              newsLogo={forbes}
              className="forbs_logo"
              href="https://www.forbes.com/sites/davidprosser/2022/09/08/realising-the-potential-of-cloud-computing-with-lucidity/?sh=669c37003bf2"
            />
          </Col>
          <Col lg={4} md={6} xs={12} className="p-0">
            <BlogCard
              heading={`Lucidity keeps cloud block storage neat, tidy and cost-efficient`}
              subheading="TechCrunch captures interesting facets in today’s cloud space, and how firms are already leveraging Lucidity to adopt the vision of “NoOps for cloud storage”."
              imgSrc={techcrunchNews}
              newsLogo={techcrunsh}
              href="https://techcrunch.com/2022/09/07/lucidity-keeps-cloud-block-storage-neat-tidy-and-cost-efficient"
            />
          </Col>
          <Col lg={4} md={6} xs={12} className="p-0">
            <BlogCard
              heading={`Lucidity just got covered in Young Turks`}
              subheading="Watch here as our co-founder, Nitin Bhadauria discusses today's industry state, Lucidity, our future expansion plans, and more, in India's longest-running startups show, Young Turks"
              imgSrc={cnbcNews}
              newsLogo={cnbc}
              href="https://www.cnbctv18.com/videos/startup/lucidity-to-utilise-53-million-fundraise-for-product-development-business-expansion-14717191.htm"
            />
          </Col>
        </Row>

        <Row className={`pe-0 position-relative ${classes.feature_cards__small}`}>
          <Col lg={8} className={`pe-0 ${styles["blog__col2"]}`}>
            <Carousel
              slideCount={3}
              slideWidth={348}
              slideTime={3000}
              smWidth={250}
              classNames1={`${styles["blog__carousel"]}`}
              classNames2={styles["slider_list"]}
              isScale={true}
            >
              <BlogCard
                heading={`Realizing The Potential Of Cloud Computing With Lucidity`}
                subheading="Forbes talks on how businesses today are missing out on many of the supposed benefits of cloud shift, and how Lucidity can help."
                imgSrc={forbesNews}
                newsLogo={forbes}
                className="forbs_logo"
                href="https://www.forbes.com/sites/davidprosser/2022/09/08/realising-the-potential-of-cloud-computing-with-lucidity/?sh=669c37003bf2"
              />
              <BlogCard
                heading={`Lucidity keeps cloud block storage neat, tidy and cost-efficient`}
                subheading="TechCrunch captures interesting facets in today’s cloud space, and how firms are already leveraging Lucidity to adopt the vision of “NoOps for cloud storage”."
                imgSrc={techcrunchNews}
                newsLogo={techcrunsh}
                href="https://techcrunch.com/2022/09/07/lucidity-keeps-cloud-block-storage-neat-tidy-and-cost-efficient"
              />
              <BlogCard
                heading={`Lucidity just got covered in Young Turks`}
                subheading="Watch here as our co-founder, Nitin Bhadauria discusses today's industry state, Lucidity, our future expansion plans, and more, in India's longest-running startups show, Young Turks"
                imgSrc={cnbcNews}
                newsLogo={cnbc}
                href="https://www.cnbctv18.com/videos/startup/lucidity-to-utilise-53-million-fundraise-for-product-development-business-expansion-14717191.htm"
              />
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
};

export default Featured;
