import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
// local
import Tick from "../../assets/images/auditDemo/tick.svg";
import forwardArrow from "../../assets/images/auditDemo/up-arrow.svg";
import works from "../../assets/images/auditDemo/works.png";

import "../../styles/auditdemo/styles.scss";
import * as classes from "../../styles/auditdemo/audit-demo.module.scss";

const Hero = () => {
  const [finalLoad, setFinalLoad] = useState(false);

  const loadForm = () => {
    if ("hbspt" in window && typeof window !== "undefined") {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "22580341",
        formId: "6655e55a-1ae1-4d1f-bfcc-1d01976da8b0",
        target: "#audit-demo-form",
      });
      setFinalLoad(true);
    }
  };

  useEffect(() => {
    loadForm();
  }, []);

  setTimeout(() => {
    loadForm();
  }, 1000);

  setTimeout(() => {
    if (!finalLoad) loadForm();
  }, 1400);

  return (
    <section className={classes.audit_demo__hero}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} md={6} xs={12} className="text-left p-0 my-auto">
            <div className={`${classes["heading"]}`}>
              <p>Wastage is meant for your</p>
              <span>
                bin, not your{" "}
                <span className={classes.cloud_primary}>cloud storage</span>
              </span>
            </div>
            <div className={classes.hero_body}>
              <span>Book a FREE storage Audit today and unearth:</span>
              <div className={classes.points}>
                <img src={Tick} alt="tick" />
                <span>Overall Disk Spend & Wastage</span>
              </div>
              <div className={classes.points}>
                <img src={Tick} alt="tick" />
                <span>Disk Utilization & Over-provisioning</span>
              </div>
              <div className={classes.points}>
                <img src={Tick} alt="tick" />
                <span>Disk Downtime Risks</span>
              </div>
            </div>
            <div className={classes.learnmore}>
              <Link to="/audit">
                Learn More!
                <img src={forwardArrow} alt="arrow" />
              </Link>
            </div>
            <div className={classes.works}>
              <img src={works} alt="works" />
            </div>
          </Col>
          <Col
            lg={5}
            md={6}
            xs={12}
            className="text-center p-0"
            id="audit-demo-form-wrapper"
          >
            <div className="landing_demo_form">
              <h3>
                Book your <span>Demo</span>
              </h3>
              <div className="custom-form-bnr" id="audit-demo-form">
                <p className="initial_msg">Schedule a Demo </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
