import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Lottie from "react-lottie-segments";
// local
import * as classes from "../../styles/auditdemo/audit-demo.module.scss";
import bulp from "../../assets/images/auditDemo/bulb.svg";
import * as graphsData from "../../assets/images/auditDemo/graphsd.json";
import Layout from "../../components/Common/Layout";
import Seo from "../../components/Common/Seo";
import Hero from "../../components/AuditDemo/Hero";
import Results from "../../components/Audit/Results";
import Featured from "../../components/AuditDemo/Featured";
import * as styles from "../../components/Common/DemoLargeButton/DemoButton.module.scss";

const lottieSetting = (data) => {
  return {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
};

const Audit = () => {
  return (
    <Layout isLanding={true}>
      <Seo
        title="Assess your cloud storage with just 1 click"
        description={`Analyze your Cloud storage spend, identify where you waste, and capture the risks of downtime, with Lucidity Audit`}
        canonicalUrl="https://www.lucidity.cloud/audit"
     />
      <Hero />
      <section className={classes.audit_demo__dyk}>
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} xs={12} className="text-center p-0">
              <div className={classes.box}>
                <div className={classes.circle}>
                  <img src={bulp} alt="bulb" />
                </div>
                <div className={classes.content}>
                  <h6>Did you know?</h6>
                  <span>
                    Around <span>70% of storage cost is wasted </span> due to
                    over-provisioning & idle resources?
                  </span>
                  <p>*As per the Audit results of 100+ enterprises</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Results title="Lucidity Storage Audit Result - Enterprises" />

      <section className={classes.audit_demo__freeaudit}>
        <Container>
          <Row className={classes.box_wrapper}>
            <Col sm={12}>
              <Row className={classes.row}>
                <Col sm={6} className="p-0">
                  <div className={classes.heading}>
                    <h2>
                      Claim your FREE <br></br>Storage Audit, today!
                    </h2>
                  </div>
                  <a href="#audit-demo-form-wrapper">
                    <Button
                      variant="outlined"
                      className={`${styles["demo_button_large"]} ${classes.landing_button}`}
                    >
                      Free Storage Audit
                    </Button>
                  </a>
                  <p>· No Security Access required for Lucidity</p>
                  <p>· Deployment in minutes</p>
                </Col>
                <Col sm={6} className={`${classes.graph} p-0`}>
                  <Lottie
                    options={lottieSetting(graphsData)}
                    goToAndPlay={{ value: 1, isFrame: true }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <Featured />
    </Layout>
  );
};

export default Audit;
